@import "~antd/dist/antd.css";

.ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
}

.custom-switch {
    margin-left: 5px;
}

.custom-label {
    font-size: 16px;
}
